import React from "react"
import styled from "styled-components"

import Page from "../../../components/page"
import { ThreeColumnsGrid } from "../../../components/containers"

const Card = styled.div`
  border: 1px solid #dedede;
  border-radius: 4px;

  background-color: #fafafa;
  color: black !important;

  height: 100%;
  padding: 32px;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ff543a;
    color: white !important;
  }
`

const CalculatorsPage = () => (
  <Page title="Calcolatori">
    <section>
      <h1>Calcolatori</h1>
      <ThreeColumnsGrid>
        <a href="calcolatori/inflazione">
          <Card>Calcolatore Inflazione</Card>
        </a>
        <a href="calcolatori/dcf">
          <Card>Calcolatore Flusso di Cassa Attualizzato</Card>
        </a>
        <a href="calcolatori/fire">
          <Card>Calcolatore FIRE</Card>
        </a>
      </ThreeColumnsGrid>
    </section>
  </Page>
)

export default CalculatorsPage
